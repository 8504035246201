import { createUseStyles } from 'react-jss';
export const useStyle = createUseStyles({
  btnPrimary: {
    border: 'none',
    background: '#0052CC',
    color: '#fff',
    fontWeight: 500,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    height: 'fit-content',
    borderRadius: '3px',
    '&.ant-tooltip-disabled-compatible-wrapper': {
      padding: '0 !important',
      boxShadow: 'none !important',
      marginRight: '3px',
      '& button': {
        border: 'none !important',
        '&[disabled]': {
          marginRight: '0 !important',
          border: 'none !important',
        },
      },
    },
    '& .ant-btn.ant-btn-primary': {
      '&[disabled]': {
        border: 'none !important',
      },
    },
    '[disabled]': {
      backgroundColor: '#f5f6f8 !important',
    },
    '&:hover, &:focus': {
      backgroundColor: 'rgba(0,82,204,0.9)',
    },
    '@media (max-width: 362px)': {
      margin: '0 0 10px 0 !important',
    },
  },
  mainSection: {
    backgroundImage: 'linear-gradient(120deg, #b4e5e36e 60%, #3a779499 100%)',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .ant-result.ant-result-info': {
      position: 'relative',
      top: '-20%',
      width: '100%',
    },
    '@media (max-with: 362px)': {
      '& .ant-result-extra': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
});
