import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './store';
import MainLayout from './components/MainLayout';
import ViewImageModal from './components/ViewImageModal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.min.css';

function App() {
  return (
    <Provider store={configureStore}>
      <MainLayout />
      <ViewImageModal />
      <ToastContainer />
    </Provider>
  );
}

export default App;
