import { isBoolean } from 'lodash';
import { ELEMENT } from './constant';

export const getUrl = () => {
  const { location } = window;
  return location.href;
};

export const getQueryParamFromUrl = (url, param = 'Expires') => {
  let urlSearch = new URLSearchParams(url);
  const res = urlSearch.get(param) || 0;
  return res;
};

export const checkElement = (element, fieldValue, defaultValue) => {
  let r = defaultValue;
  switch (element) {
    case ELEMENT.checkBox: {
      if (isBoolean(defaultValue) && fieldValue) {
        const temp = fieldValue?.split('\n');
        r = defaultValue ? [temp[0]] : [];
      }
      break;
    }
    case ELEMENT.radioButtons: {
      if (!defaultValue) {
        r = null;
      }
      if (defaultValue === true) {
        const temp = fieldValue?.split('\n');
        r = temp[0];
      }
      break;
    }
    case ELEMENT.listBox: {
      const temp = fieldValue?.split('\n');
      if (!temp?.includes(defaultValue)) {
        r = null;
      }
      break;
    }
    default:
      break;
  }

  return r;
};

export const toCamelCase = (h) => {
  return h
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const getCustomAndDirectFieldAsset = (
  isEdit,
  selectedAsset,
  assetModels,
  elements = null,
  tailElements = null,
) => {
  const customField = selectedAsset
    ? !selectedAsset?.customFields?.length
      ? null
      : JSON.parse(selectedAsset?.customFields)
    : null;
  const directField = selectedAsset
    ? !selectedAsset?.directCustomFields?.fields?.length
      ? null
      : JSON.parse(selectedAsset?.directCustomFields?.fields)
    : null;
  const res = assetModels?.customField?.reduce((result, field) => {
    const defaultValueFromAsset = customField?.find((c) => c.id === field.id)?.defaultValue;
    const defaultValueFromModel = assetModels?.customFieldDefaultValue?.find(
      (m) => m?.CustomFieldId === field.id,
    )?.defaultValue;
    const defaultValue = isEdit ? defaultValueFromAsset : defaultValueFromAsset || defaultValueFromModel;

    const key = toCamelCase(field?.name?.toLowerCase());
    if (elements) {
      elements[key] = field?.name;
    }
    const resVal = checkElement(field?.element, field?.fieldValues, defaultValue);
    const a = {
      ...result,
      [key]: resVal,
    };

    return a;
  }, {});
  const resDirect = directField?.reduce((result, field) => {
    const customField = selectedAsset?.directCustomFields?.customFields?.find((e) => {
      return e.id === field?.id;
    });
    if (!customField || !customField.length) return result;
    const key = toCamelCase(customField?.name?.toLowerCase());
    if (elements) {
      elements[key] = customField?.name;
    }
    const value = field?.defaultValue;
    const resVal = customField?.element && checkElement(customField?.element, customField?.fieldValues, value);
    const a = {
      ...result,
      [key]: resVal,
    };
    return a;
  }, {});
  if (tailElements) {
    if (elements) {
      Object.keys(tailElements).map((k) => {
        return (elements[k] = tailElements[k]);
      });
    }
  }
  return { ...res, ...resDirect };
};

export const currentTimestampInSecond = () => `${parseInt(new Date().getTime() / 1000)}`;

export const getAssetInfoFromUrl = () => {
  const state = getQueryParamFromUrl(window.location.search, 'state');
  const info = decodeURIComponent(state || '')?.split(',');
  return { id: info[0], tag: info[1] };
};

export const getBearerTokenFromUrl = () => {
  const token = getQueryParamFromUrl(window.location.search, 'code');
  return token;
};

export const createStateForAuthorizationUrl = () => {
  const id = getQueryParamFromUrl(window.location.search, 'id');
  const tag = getQueryParamFromUrl(window.location.search, 'tag');
  const state = `${id}%2C${tag}`;
  return state;
};
