import { createAction } from '@reduxjs/toolkit';
import * as types from './types';

export default {
  getJWT: createAction(types.GET_JWT),
  setJWT: createAction(types.SET_JWT),
  setBearerToken: createAction(types.SET_BEARER_TOKEN),
  setAccessToken: createAction(types.SET_ACCESS_TOKEN),
  setCloudId: createAction(types.GET_CLOUDID),
  setProcessing: createAction(types.SET_PROCESSING),
  fetchAsset: createAction(types.FETCH_ASSET),
  setAssetInfo: createAction(types.SET_ASSET_INFO),
  setSelectedAsset: createAction(types.SET_SELECTED_ASSET),
  setAssetDirectFields: createAction(types.SET_ASSET_DIRECT_FIELDS),
  setSelectedSiteId: createAction(types.SET_SELECTED_SITE_ID),
};
