import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducer';
import rootSaga from './saga';

const configureStore = (initialState = {}) => {
  const rootReducer = createReducer();
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];

  if (process.env.NODE_ENV === `development`) {
    const loggerMiddleware = createLogger({ collapsed: true });
    middlewares.push(loggerMiddleware);
  }

  const store = createStore(rootReducer, initialState, applyMiddleware(...middlewares));

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
