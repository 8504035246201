import React from 'react';
import { Result, Button, Tooltip } from 'antd';
import assetITIcon from '../../assets/img/assetIT_icon.png';
import { ASSET_IT_MODULE_KEY, getQueryParamFromUrl, PLUGIN_KEY } from '../../utils';
import { useStyle } from './styles';

const MainLayout = () => {
  const classes = useStyle();
  const id = getQueryParamFromUrl(window.location.search, 'id');
  const tag = getQueryParamFromUrl(window.location.search, 'tag');
  const host = getQueryParamFromUrl(window.location.search, 'instance');
  const pageEndpoint = `https://${host}.atlassian.net/plugins/servlet/ac/`;
  const target = `${pageEndpoint}${PLUGIN_KEY}/${ASSET_IT_MODULE_KEY}?ac.itemId=${id}&ac.scan=${true}`;
  return (
    <div className={classes.mainSection}>
      <Result
        icon={<img style={{ maxWidth: '80px', objectFit: 'contain' }} src={assetITIcon} alt="" />}
        title="Asset Info"
        subTitle={`ID: ${id}, Asset Tag: ${tag}`}
        extra={[
          <Button type="primary" key="console" className={classes.btnPrimary} target="_blank" href={target}>
            View details
          </Button>,
          <Tooltip
            type="primary"
            className={classes.btnPrimary}
            key="contact"
            title="This feature is under construction"
            placement="top"
          >
            <Button disabled={true}>Contact Admin</Button>
          </Tooltip>,
        ]}
      />
    </div>
  );
};

export default MainLayout;
