import { takeLatest, call, put, select } from 'redux-saga/effects';
import { AssetsItApis } from '../../api';
import { TOAST_DEFAULT_OPTIONS } from '../../utils';
import { toast } from 'react-toastify';
import actions from './actions';
import { isEmpty, isString } from 'lodash';

export function* getJWT() {
  yield takeLatest(actions.getJWT, function* ({ payload }) {
    try {
      const { data: dataPayload, pushHistory } = payload;
      yield put(actions.setProcessing(true));
      const response = yield call(AssetsItApis.getJWT, dataPayload);
      const { data } = response;
      yield put(actions.setJWT(data));
      pushHistory();
      yield put(actions.setProcessing(false));
    } catch (e) {
      const { response } = e;
      if (response.status === 404) toast.error(response.data, TOAST_DEFAULT_OPTIONS);
      yield put(actions.setProcessing(false));
    }
  });
}

export function* fetchAsset() {
  yield takeLatest(actions.fetchAsset, function* () {
    try {
      yield put(actions.setProcessing(true));
      const { assetInfo, jwt } = yield select((state) => state.assets);
      const dataSend = {
        id: parseInt(assetInfo.id),
        tag: assetInfo.tag,
        jwt,
      };
      const response = yield call(AssetsItApis.fetchAsset, dataSend);
      const { data } = response;
      const { asset, directCustomFields } = data;
      if (isEmpty(asset)) {
        const mess = 'Asset not found';
        toast.error(mess, TOAST_DEFAULT_OPTIONS);
      } else {
        const dataAsset = { ...asset, directCustomFields };
        yield put(actions.setSelectedAsset(dataAsset));
      }
      yield put(actions.setProcessing(false));
    } catch (e) {
      const { response } = e;
      const mess = 'Failed to get Asset details';
      if (isString(response.data)) toast.error(response.data, TOAST_DEFAULT_OPTIONS);
      else toast.error(mess, TOAST_DEFAULT_OPTIONS);
      yield put(actions.setProcessing(false));
    }
  });
}
