import axios from 'axios';
const baseURL = process.env.REACT_APP_ENV
  ? 'https://dev.asset-it.atlas.devsamurai.com'
  : 'https://asset-it.atlas.devsamurai.com';
const createAxios = () =>
  axios.create({
    baseURL,
  });

const axiosInstance = createAxios();

export default axiosInstance;
