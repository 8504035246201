import actions from './actions';
import { createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isProcessing: false,
  selectedAsset: null,
  directFields: null,
  jwt: null,
  cloudId: null,
  bearerToken: null,
  accessToken: null,
  assetInfo: null,
  selectedSiteId: null,
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(actions.setProcessing, (state, actions) => {
      state.isProcessing = actions.payload;
    })
    .addCase(actions.setBearerToken, (state, actions) => {
      state.bearerToken = actions.payload;
    })
    .addCase(actions.setAccessToken, (state, actions) => {
      state.accessToken = actions.payload;
    })
    .addCase(actions.setAssetInfo, (state, actions) => {
      state.assetInfo = actions.payload;
    })
    .addCase(actions.setJWT, (state, actions) => {
      state.jwt = actions.payload;
    })
    .addCase(actions.setCloudId, (state, actions) => {
      state.cloudId = actions.payload;
    })
    .addCase(actions.setSelectedAsset, (state, actions) => {
      state.selectedAsset = actions.payload;
    })
    .addCase(actions.setAssetDirectFields, (state, actions) => {
      state.directFields = actions.payload;
    })
    .addCase(actions.setSelectedSiteId, (state, actions) => {
      state.selectedSiteId = actions.payload;
    });
});
