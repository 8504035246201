import React, { useEffect, useState } from 'react';
import ImgsViewer from 'react-images-viewer';
import { EVENTS, eventBus } from '../../utils';

const ViewImageModal = () => {
  const [state, setState] = useState({
    show: false,
    images: [],
    index: 0,
  });

  const handleCancel = () => {
    setState({ show: false });
  };
  const handleShow = (data) => {
    setState({ ...state, ...data, show: true });
  };
  const handleClickPrev = () => setState({ ...state, index: state.index - 1 });
  const handleClickNext = () => setState({ ...state, index: state.index + 1 });

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_VIEW_IMAGE_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_VIEW_IMAGE_MODAL, handleShow);
    };
  }, []);

  return (
    <>
      <ImgsViewer
        isOpen={state.show}
        imgs={state.images ?? []}
        currImg={state.index}
        onClose={handleCancel}
        onClickPrev={handleClickPrev}
        onClickNext={handleClickNext}
      />
    </>
  );
};

export default ViewImageModal;
