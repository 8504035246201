export const EVENTS = { OPEN_VIEW_IMAGE_MODAL: 'OPEN_VIEW_IMAGE_MODAL' };
export const FORMAT_DATE_TYPE_WITHOUT_TIME = 'll';
export const PLUGIN_KEY = 'com.devsamurai.plugin.jira.asset';
export const ASSET_IT_MODULE_KEY = 'asset-it-page';
export const ELEMENT = {
  textBox: 'textBox',
  listBox: 'listBox',
  textArea: 'textArea',
  checkBox: 'checkbox',
  radioButtons: 'radioButtons',
};
export const TOAST_DEFAULT_OPTIONS = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};
