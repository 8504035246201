import { api } from '../utils';

export default {
  // Asset
  getJWT: (data) => api.post('/ast/app-token', data),
  fetchAsset: (data) =>
    api.get(`/ast/asset-details?id=${data.id}&tag=${data.tag}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${data.jwt}`,
      },
    }),
};
