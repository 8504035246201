const prefix = 'ASSETS/';
export const INITIAL = `${prefix}INITIAL`;
export const SET_PROCESSING = `${prefix}SET_PROCESSING`;
export const GET_JWT = `${prefix}GET_JWT`;
export const GET_CLOUDID = `${prefix}GET_CLOUDID`;
export const SET_JWT = `${prefix}SET_JWT`;
export const SET_ASSET_INFO = `${prefix}SET_ASSET_INFO`;
export const SET_BEARER_TOKEN = `${prefix}SET_BEARER_TOKEN`;
export const SET_ACCESS_TOKEN = `${prefix}SET_ACCESS_TOKEN`;
export const SET_CLOUDID = `${prefix}SET_CLOUDID`;
export const FETCH_ASSET = `${prefix}FETCH_ASSET`;
export const SET_SELECTED_ASSET = `${prefix}SET_SELECTED_ASSET`;
export const SET_ASSET_DIRECT_FIELDS = `${prefix}SET_ASSET_DIRECT_FIELDS`;
export const SET_SELECTED_SITE_ID = `${prefix}SET_SELECTED_SITE_ID`;
